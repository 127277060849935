.login-page {
    /* Location of the image */
    background-image: url(/media/images/background-login.jpg);

    /* Image is centered vertically and horizontally at all times */
    background-position: center center;

    /* Image doesn't repeat */
    background-repeat: no-repeat;

    /* Makes the image fixed in the viewport so that it doesn't move when
       the content height is greater than the image height */
    background-attachment: fixed;

    /* This is what makes the background image rescale based on its container's size */
    background-size: cover;

    /* Pick a solid background color that will be displayed while the background image is loading */
    background-color:#464646;

    /* SHORTHAND CSS NOTATION
     * background: url(background-photo.jpg) center center cover no-repeat fixed;
     */
}

.login-logo span{
    display: none !important;
}

.login-logo img {
    width: auto !important;
    height: 80px !important;
}

.login-logo a, .register-logo a {
    color: #f4f4f4 !important;
}

.skin-black .main-header > .logo {
    background-color: #3c8dbc;
    color: #f4f4f4;
    border-bottom: 0 solid transparent;
    border-right: 1px solid #eee;
}

.skin-black .main-header .navbar {
    background-color: #3c8dbc;
}

.skin-black .main-header .navbar .nav > li > a {
    color: #f4f4f4;
}

.skin-black .main-header .navbar > .sidebar-toggle {
    color: #f4f4f4;
    border-right: 1px solid #eee;
}

.breadcrumb > .active {
    color: #f4f4f4;
}

.custom_title_with_form .box-header h4 {
    font-size: 1.8em;
    border-bottom: 1px dashed #EB5D59;
    display: block;
    padding-bottom: 5px;
}

.required:after {
    color: red;
}

/* CUSTOM */

.btn-primary {
    background-color: #2F856C !important;
    border-color: #2F856C !important;
}

/*link login form*/
.login-box-body a, .btn-link {
    color: #2F856C !important;
}

/*Barra superior de navegação*/
.skin-black .main-header .navbar, .skin-black .main-header > .logo {
    background-color: #222d32 !important;
    border-color: #222d32 !important;
}

/*Boxes da aplicação*/
.box {
    border-color: #2F856C !important;
}

.skin-black .main-header .navbar > .sidebar-toggle,
.skin-black .main-header .navbar .navbar-nav > li > a,
.skin-black .main-header .navbar .navbar-custom-menu .navbar-nav > li > a,
.skin-black .main-header .navbar .navbar-right > li > a {
    border: 0 none !important;
}

/*border superior das tabs*/
.nav-tabs-custom > .nav-tabs > li.active {
    border-color: #2F856C !important;
}

/* Altura da logo */
.logo img {
    height: 40px !important;
}

/*Fundo do quadro onde tem a edição do usuário, do lado direito*/
.bg-light-blue, .label-primary, .modal-primary .modal-body {
    background-color: #2F856C !important;
}

.box {
    box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03), 0 0.9375rem 1.40625rem rgba(4,9,20,0.03), 0 0.25rem 0.53125rem rgba(4,9,20,0.05), 0 0.125rem 0.1875rem rgba(4,9,20,0.03);
    transition: all .2s;
}